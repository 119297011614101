import api from '../utils/api'

export default {
  get (userId) {
    return api.get(`users/${userId}`)
  },
  update (userId, user) {
    return api.patch(`users/${userId}`, user)
  },
  getAll (params) {
    return api.get('users', { params: params })
  },
  getByMedialogId (medialogId) {
    return api.get(`users/pid/${medialogId}`)
  },
  bankCards (userId) {
    return api.get(`users/${userId}/bankcards`)
  },
  transactions (userId) {
    return api.get(`users/${userId}/transactions`)
  },
  logs (userId, params) {
    return api.get(`users/${userId}/logs`, { params: params })
  },
  async switchToAccount (userId) {
    return await api.post(`users/${userId}/switch-to-account`)
  },
  async switchToAdminAccount (username) {
    return await api.get(`users?_switch_user=${username}`)
  },
  async oauthAccessToken (userId, clientId) {
    return await api.post(`users/${userId}/oauth-access-token`, { clientId })
  },
  async ehr ({ limit, search }) {
    return await api.get('ehr', { params: { limit, search } })
  }
}
